<template>
  <div class="enhancer-container">
    <component :is="isBatch ? MultipleImageEnhancer : ImageEnhancer" :imageList="imageList" @back="handleBack"
      v-if="!isLoading"></component>
  </div>
</template>

<script setup>
import ImageEnhancer from "@/components/models/image-enhancer/index.vue";
import MultipleImageEnhancer from "@/components/models/image-enhancer/multiple-enhancer.vue";
import { ref, defineProps, defineEmits, onMounted } from "vue";
import UnloadHandler from "@/utils/beforeunload.js";

onMounted(() => {
  console.log('isBatch', props.isBatch)

});

const props = defineProps({
  imageList: Array,
  isBatch: Boolean,
  isLoading: Boolean,
});

UnloadHandler(props.isBatch);

const emits = defineEmits(["back"]);

const handleBack = () => {
  emits("back");
};
</script>

<style lang="less" scoped></style>
