<template>
  <div class="relative">
    <image-enhancer v-if="uploadComplete" :imageList="uploadImageList" @back="handleBack" :isBatch="isBatch"
      :isLoading="loading" />
    <image-upload @uploadComplete="handleUploadComplete" @isBatch="setIsBatch" @enterLoading="setLoading"
      @uploadFileList="setUploadFileList" v-else />
  </div>
</template>
<script setup>
import ImageUpload from "./image-upload.vue";
import ImageEnhancer from "./image-enhancer.vue";
import { ref, watch } from "vue";
import { eventTracking } from "@/utils/eventTracking.js";
import { uploadImageSingle } from "@/api/upload/upload.js";

const uploadComplete = ref(false);
const uploadImageList = ref([]);
const isBatch = ref(false);
const loading = ref(false);


const handleUploadComplete = () => {
  uploadComplete.value = true;
};

const handleBack = () => {
  uploadImageList.value = [];
  uploadComplete.value = false;
};

const setIsBatch = (value) => {
  isBatch.value = value;
};

const setLoading = (value) => {
  loading.value = value;
};

const setUploadFileList = (fileArray) => {
  uploadImageList.value = fileArray
  uploadComplete.value = true;
  eventTracking("booltool_page_upload", { tool_name: "enhancer", is_batch: isBatch.value })
}

// 监听图片上传
watch(() => [...uploadImageList.value], (newAarry, old) => {
  uploadImageList.value.forEach(async (file, index) => {
    loading.value = false;
    try {
      const { code, data } = await uploadImageSingle({ file: file.originFile });
      if (code === 0) {
        uploadImageList.value[index].s3Uri = data.url
      }
    } catch (error) {
    }
  })
})
</script>

<style lang="less"></style>
