<template>
  <div class="multiple-image-enhancer">
    <!-- bar -->


    <div class="header flex justify-between items-center">
      <back-btn @back="handleBack" />
      <div v-if="!taskComplete" class="loading-button-mask"></div>
      <custom-btn v-if="!isSelectState" :customStyle="customStyle" @click="handleDownload" :showPrefixIcon="false"
        :disabled="!taskComplete" :disabledStyle="{ background: '#d5c6ff', color: '#fff' }">
        Batch download
      </custom-btn>
      <div v-else class="select-all-box flex" @click="handleSelectAll">
        <img :src="isSelectAll ? iconNoSelect : select_all_icon" />
        <div>Select all</div>
      </div>
    </div>
    <!-- image show  -->
    <div class="multiple-image-item flex ">
      <div :style="gridStyle">
        <div v-for="(item, index) in imageListCopy" :key="index" style="width: 326px; height: 326px;">
          <image-board class="image-board">
            <div v-if="item.loading" class="loading-mask"></div>
            <div class="mask-box" v-if="isSelectState" @click="handleSelectItem(item, index)"
              :style="{ background: !selectedItemIndexList.includes(index) ? 'rgba(0, 0, 0, 0.15)' : none }">
              <img :src="selectedItemIndexList.includes(index)
                  ? iconSelected
                  : iconCheckBox
                " class="icon-checkbox" />
            </div>

            <!-- <a-image :src="item.originUrl" width="100%" height="100%" /> -->
            <img crossOrigin :src="item.originUrl" />
          </image-board>
        </div>

      </div>
    </div>
    <!-- <loading-mask v-else /> -->
    <!-- 悬浮操作按钮 -->
    <div class="btn-position" v-if="isSelectState">
      <div class="flex justify-center">
        <custom-btn activeColor="linear-gradient(289deg, #EDEDED 18.5%, #EDEDED 99.95%)" :customStyle="{
          width: '138px',
          height: '42px',
          background: '#fff',
          'border-radius': '100px',
          color: '#060606',
          'margin-right': '48px',
          'box-shadow': '0px 6px 18px rgba(0, 0, 0, 0.1)',
        }" @click="handleCancel">Cancel
        </custom-btn>
        <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)" :customStyle="{
          width: '138px',
          height: '42px',
          background:
            'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
          'border-radius': '100px',
          color: '#FFFFFF',
          'box-shadow': '0px 6px 18px rgba(138, 98, 255, 0.43)',
        }" @click="handleBatchDownload">Download{{
  ` ( ${imageListCopy.length - selectedItemIndexList.length} )`
}}
        </custom-btn>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineProps, onMounted, defineEmits, onUnmounted, watch } from "vue";
import ImageBoard from "@/components/boolv-ui/image-board";
import {
  MessageSuccess,
  MessageError,
  MessageLoading,
  destroy,
} from "@/components/boolv-ui/Message/index.js";
import { eventTracking } from "@/utils/eventTracking.js";
import { compressByImagesUrl } from "@/utils/downloadZip.js";
import BackBtn from "@/components/boolv-ui/back-btn";
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import select_all_icon from "@/assets/select_all_icon.svg";
import { getBatchTaskId, getAsyncResult } from "@/api/models/image-enhancer.js";
import iconSelected from "@/assets/common-icons/checkbox_icon.svg";
import iconNoSelect from "@/assets/common-icons/icon_no_select.svg";
import iconCheckBox from "@/assets/common-icons/icon_check_box.svg";
import { useUserStore } from '@/store/user.js'
import { useSideStore, useViewStore } from "@/store";
import { authenticator } from "@/utils";
const sideStore = useSideStore();
const viewStore = useViewStore();
const user = useUserStore();
var md5 = require("md5");

const emits = defineEmits(["back"]);

const props = defineProps({
  imageList: Array,
});

const taskComplete = ref(false);
const customStyle = reactive({
  width: "180px",
  height: "42px",
  background: "linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)",
  "border-radius": "60px",
});
const currentImageList = ref([]);
const isSelectState = ref(false);
const isSelectAll = ref(false);
const selectedItemIndexList = ref([]);
const enhancerResult = ref([])
const imageListCopy = reactive(props.imageList);
// 是否有任务进程
const hasTaskProgress = ref(false)

onMounted(() => {
  // 上传开始，展示mask
  handleImageStartLoading()
  // viewStore.setMinViewWidth('1400px')
});

const handleImageStartLoading = () => {
  sideStore.showGlobalMask = true;
  MessageLoading(
    "Processing, it will take a while",
    0,
    "",
    null,
    false,
    closeRequest
  );
}
watch(() => props.imageList, async () => {
  imageListCopy.value = props.imageList
  await judgeUploadProgress(props.imageList)
  processArray(imageListCopy.value);
}, { deep: true })

// 判断是否有未上传完的图
const judgeUploadProgress = (imageList) => {
  return new Promise((resolve, reject) => {

    let item = imageList.find(item => !item.s3Uri)
    if (item) {
      reject()
    } else {
      resolve()
    }
  })
}
const handleDownload = () => {
  isSelectState.value = true;
};

const handleSelectAll = () => {
  isSelectAll.value = !isSelectAll.value;

  if (isSelectAll.value) {
    selectedItemIndexList.value = imageListCopy.map((_, index) => index);
  } else {
    selectedItemIndexList.value = [];
  }
};

const handleSelectItem = (item, index) => {
  const idx = selectedItemIndexList.value.findIndex((e) => index === e);
  if (idx >= 0) {
    selectedItemIndexList.value.splice(idx, 1);
  } else {
    selectedItemIndexList.value.push(index);
  }
  isSelectAll.value = selectedItemIndexList.value.length !== 0
};

const gridStyle = reactive({
  'display': 'grid',
  'grid-template-columns': 'repeat(4, 326px)',
  'grid-template-rows': '326px 326px',
  'grid-gap': '32px 25px'
})

const processArray = async (array) => {
  // 请求之后将状态设置为true只请求一次，因为结果会影响原数组，file数组深拷贝比较麻烦，所以用变量控制
  if (hasTaskProgress.value) {
    return
  }
  hasTaskProgress.value = true
  //新的调用方式
  let imageArray = array.map((item, index) => {
    let newItem = {};
    newItem["inputS3Uri"] = item.s3Uri;
    newItem["renderId"] = index;
    return newItem;
  });

  try {
    const { code, data } = await getBatchTaskId({ imageList: imageArray });
    if (code === 0) {
      const task = data.taskId;
      pollTask(task, imageArray);
      authenticator.refresh();
    } else {
      destroy()
    }
  } catch (error) {
    destroy()
    sideStore.showGlobalMask = false;
  }
};

//拿task id 轮训结果
const pollTask = async (taskId, imageArray) => {
  while (!taskComplete.value) {
    // make a request to the task status end
    const res = await getAsyncResult({ taskId: taskId });
    const { code, data } = res;
    enhancerResult.value = data
    if (code === 0) {
      //先通过renderId 去匹配 ,再去执行当前的动作
      for (let i = data.length - 1; i >= 0; i--) {
        let { success, outputS3Uri, outputUrl, renderId } = data[i];
        if (success) {
          imageListCopy[renderId].loading = false;
          imageListCopy[renderId].outputS3Uri = outputS3Uri;
          imageListCopy[renderId].originUrl = outputUrl;
        } else {
          //处理图片失败的情况
        }
      }

      if (data.length === imageArray.length) {
        taskComplete.value = true;
        destroy();
        MessageSuccess("Processing completed");
        sideStore.showGlobalMask = false;
        setTimeout(() => {
          destroy()
        }, 2000)
      }
    } else {
      sideStore.showGlobalMask = false;
    }

    // wait for 3 seconds before making the next request
    await new Promise((resolve) => setTimeout(resolve, 2000));
  }
};

const closeRequest = () => {
  //TODO
  //
  destroy();
};

const handleCancel = () => {
  isSelectState.value = false;
};

const handleBatchDownload = async () => {
  //批量下载 ，传递urlList
  const urlList = imageListCopy.map((item, index) => {
    if (!selectedItemIndexList.value.includes(index)) {
      return item.originUrl;
    }
  });
  storeCurrentImage();
  compressByImagesUrl(urlList, `imageEnhancer-${md5(Date.now())}.zip`);
  eventTracking("booltool_page_download", { tool_name: "enhancer", is_batch: true, is_success: true, })
};
//埋点
const storeCurrentImage = () => {
  let currentList = [];
  imageListCopy.forEach((item) => {
    currentList.push(item.outputS3Uri);
  })
  sessionStorage.setItem('currentImageId', JSON.stringify(currentList));
}
const handleBack = () => {
  closeRequest()
  emits("back");
};

onUnmounted(() => {
  destroy()
  sideStore.showGlobalMask = false;
})
</script>

<style lang="less" scoped>
.header {
  position: relative;
  height: 42px;
  margin-bottom: 36px;
}

.multiple-image-enhancer {
  padding: 16px 36px 58px;
  // padding: 0 32px;
  min-width: 1400px;
}

.image-board {
  width: 100%;
  height: 100%;
  // margin-bottom: 36px;
}

.loading-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  opacity: 0.5;
  top: 0;
  z-index: 10;
}

.loading-button-mask {
  position: absolute;
  width: 180px;
  height: 42px;
  border-radius: 60px;
  background: #fff;
  opacity: 0.5;
  right: 0px;
  z-index: 999;
}

.select-all-box {
  cursor: pointer;

  img {
    margin-right: 6px;
  }
}

.multiple-image-item {
  justify-content: center;
}

.mask-box {
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 0;
  top: 0;
  text-align: right;
  z-index: 9;
}

.btn-position {
  width: 100%;
  position: fixed;
  bottom: 68px;
  z-index: 99;
}

.icon-checkbox {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
</style>
