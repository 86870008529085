<template>
  <div class="common-upload-container flex justify-center items-center" :class="{ 'min-client-height': minClient }">
    <div class="upload-position-container">
      <div class="upload-box upload-single flex justify-center items-center" @mouseenter="handleShowImageList"
        @mouseleave="handleHiddenImageList" v-authenticate:IMAGE_ENHANCER>
        <commonUpload :fileList="fileList" :setFileList="setFileList" creditTip="max 1080p resolution" :multiple="false"
          :customRequest="customRequest" :reUpload="reUpload" accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG"
          :iconUrl="iconUploadSingle" :iconStyle="{
              width: '46px',
              height: '58px',
              'margin-bottom': '25px',
            }" c />
      </div>
      <div class="image-box-background" @mouseenter="handleShowImageList" @mouseleave="handleHiddenImageList"
        v-if="isShowImageList">
        <div class="inner-image-box">
          <div class="box-arrow"></div>
          <p class="tooltip-title">No image? Try one of these</p>
          <div class="example-images-box">
            <div class="example-image-icon-box" v-for="item in imagesList" :key="item.id"
              v-authenticate:IMAGE_ENHANCER="{ ok: () => handleSelectImage(item) }">
              <div class="mask-item"></div>
              <img :src="item.iconSrc" class="example-image-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="upload-box upload-multiple flex justify-center items-center" v-authenticate:BOOLTOOL_BATCH_PROCESS>
      <commonUpload :fileList="fileList" :multiple="true" :setFileList="setFileList" :reUpload="reUpload"
        accept=".JPG,.jpg,.png,.PNG,.webp,.WEBP,.jpeg,.JPEG" :customRequest="customBatchRequest" :limitNum="20"
        :iconUrl="iconUploadMultiple" :iconStyle="{
            width: '57px',
            height: '69px',
            'margin-bottom': '25px',
          }" placeHolder="Batch upload (Max 20 images)"
        :creditTip="batchProcessResource.unlimitedNum ? '&nbsp;' : `Balance: ${batchProcessResource.num || 0}`" />
    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits, onUnmounted, onMounted } from "vue";
import commonUpload from "@/components/custom-upload/index.vue";
import { getFileFromUrl } from "@/utils/index.js";
import { storeToRefs } from 'pinia'
import {
  MessageLoading,
  MessageError,
  destroy,
} from "@/components/boolv-ui/Message/index.js";

import iconUploadSingle from "@/assets/upload-images/icon_upload_single.svg";
import iconUploadMultiple from "@/assets/upload-images/icon_upload_mutiple_pro.svg";
import enhancer1 from "./img/enhancer1.webp";
import enhancer2 from "./img/enhancer2.webp";
import enhancer3 from "./img/enhancer3.webp";
import enhancer1_src from "./img/enhancer1_src.webp";
import enhancer2_src from "./img/enhancer2_src.webp";
import enhancer3_src from "./img/enhancer3_src.webp";

import { useSideStore, useViewStore, useSubscriptionInfo } from '@/store/index.js'

const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)
const uploadFileList = ref([]);
const isShowImageList = ref(false);
const isBatch = ref(false)
const reUpload = ref(false)
const exceedMaxPx = ref(false)
const countNum = ref(0)
const subscriptionInfo = useSubscriptionInfo();
const batchProcessResource = subscriptionInfo.getUserResource('BOOLTOOL_BATCH_PROCESS');
const imagesList = ref([
  {
    id: 1,
    iconSrc: enhancer1,
    src: enhancer1_src,
  },
  {
    id: 2,
    iconSrc: enhancer2,
    src: enhancer2_src,
  },
  {
    id: 3,
    iconSrc: enhancer3,
    src: enhancer3_src,
  },
]);
const preload = () => {
  imagesList.value.forEach(item => {
    const img = new Image();
    img.src = item.iconSrc;
  })
}
onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  preload();
})
const emits = defineEmits(["uploadComplete", "currentMode", "enterLoading"]);
const setIsBatch = (value) => {
  isBatch.value = value;
  emits("isBatch", value);
}

const customRequest = async () => {
  setIsBatch(false)
  reUpload.value = true
};

const handleSelectImage = async (item) => {
  setIsBatch(false)
  sessionStorage.setItem("sourceImageId", JSON.stringify([`default_image_${item.id}`]));
  const file = await getFileFromUrl(item.src);
  setFileList([file])
};

const customBatchRequest = async () => {
  setIsBatch(true)
  reUpload.value = true
};

const checkImagePixel = (fileList) => {

  return new Promise((resolve, reject) => {

    fileList.forEach((file) => {
      let reader = new FileReader()
      reader.readAsDataURL(file.originFileObj || file)
      reader.onload = () => {
        const img = new Image()
        img.src = reader.result
        img.onload = () => {
          let width = img.width
          let height = img.height

          if ((width > 1080 && height > 1080) || (height > 1080 && width > 1080)) {
            destroy()
            MessageError('Max 1080p resolution')
            exceedMaxPx.value = true
            countNum.value += 1
            reject()
            return
          } else {
            countNum.value += 1
          }

          if (countNum.value === fileList.length && !exceedMaxPx.value) {
            resolve()
          }
        }
      }
    })

  })
}

const setFileList = async (fileList = [], isBatch) => {

  exceedMaxPx.value = false
  destroy()
  // viewStore.setMinViewWidth('1400px')
  MessageLoading("Uploading, it will take a while");
  const formatFileList = fileList.map(file => {
    return file.originFileObj || file
  })

  // 初始化图片数组
  const fileArray = formatFileList.map((file) => {
    return {
      originFile: file,
      originUrl: URL.createObjectURL(file),
      loading: true
    }
  });

  // await checkImagePixel(fileList)
  destroy()
  if (!exceedMaxPx.value) {
    setIsBatch(isBatch)
    uploadFileList.value = fileArray;
    emits("isBatch", isBatch);
    emits("uploadFileList", fileArray);
  }

};

const handleShowImageList = () => {
  isShowImageList.value = true;
};

const handleHiddenImageList = () => {
  isShowImageList.value = false;
};

onUnmounted(() => {
  destroy()
})
</script>

<style lang="less" scoped>
.common-upload-container {
  min-height: calc(100vh - 60px);
  padding: 60px 0;
  min-width: 1400px;

  .tooltip-card {
    width: 394px;

    left: "280px";
  }

  :global(.ant-tooltip-content) {
    width: 394px;
    height: 100%;
  }

  :global(.ant-tooltip-inner) {
    width: 394px;
    height: 100%;
    color: #8f959e;
  }
}

.upload-box {
  width: 277px;
  height: 196px;
  padding-top: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f8f5ff;
  }
}

.upload-single {
  margin-right: 34px;
}

.example-images-container {
  width: 394px;

  background-color: green;
}

.example-image-icon {
  width: 110px;
  height: 110px;
  border-radius: 4px;
  object-fit: cover;
}

.example-images-box {
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
}

.image-box-background {
  width: 394px;
  height: 230px;
  position: absolute;
  padding-top: 14px;
  left: -60px;
}

.inner-image-box {
  width: 394px;
  height: 212px;
  position: absolute;
  // background-color: green;
  margin-top: 14px;

  background: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.upload-position-container {
  position: relative;
}

.example-image-icon-box {
  position: relative;
  cursor: pointer;
  background-color: #F6F6F6;
  width: 110px;
  height: 110px;
  border-radius: 4px
}

.mask-item {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 4px;
  opacity: 0;
  background-color: #000;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }
}

.inner-image-box:before {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: -32px;
  padding: 0;
  border: 16px solid transparent;
  border-color: transparent transparent #ffffff transparent;
  content: '';
  z-index: 12;
}

.inner-image-box:after {
  width: 0px;
  height: 0px;
  position: absolute;
  left: 50%;
  margin-left: -9.5px;
  top: -35px;
  padding: 0;
  border: 17.5px solid transparent;
  border-color: transparent transparent #f1f1f1 transparent;
  content: '';
  z-index: 10
}
</style>
